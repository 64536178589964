.footer-item {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  background: #fff;
  z-index: 1;

  .left-item {
    display: flex;
    align-items: center;
    height: 100%;
    width: 350px;

    .icon-block1 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 20px;

      .icon1 {
        margin-left: 10px;
        font-size: 20px;
      }

      &:hover {
        cursor: pointer;
        background: #eee;
      }
    }
  }

  .center-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    .icon-block2 {
      background: #fff;
      border: 1px solid rgb(223 223 223);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;

      .icon2 {
        color: #555;
        font-size: 20px;

        &.red {
          color: #d93025;
        }
      }

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 10px #ddd;
      }

      &.red-bg {
        background: #d93025;

        .icon {
          color: #fff;
        }
      }
    }
  }

  .right-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 350px;

    .icon-block3 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 146px;
      color: #555;

      .icon3 {
        font-size: 20px;
        margin-bottom: 10px;
      }

      .title {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
      }

      &:hover {
        cursor: pointer;
        background: #eee;
      }
    }
  }
}