
.frame-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 350px;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  border-radius: 0 0 0 10px;
  overflow: hidden;

  .header-items {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    flex: 1;
    border-right: 1px solid rgb(245 245 245);
    position: relative;
    
    .alert-circle-icon {
      position: absolute;
      background: #04796a;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid #fff;
      top: 10px;
      right: 20px;
    }  

    &.date-block {
      font-size: 22px;
      color: #5f6368;
      min-width: 120px;
      font-weight: 400;
    }

    &.icon-block {
      .icon {
        color: #505357;
        font-size: 22px;

        &.profile {
          font-size: 32px;
          color: #04796a;
        }
      }

      &:hover {
        cursor: pointer;
        background: #eee;
      }
    }
  }
}
